import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const fav_card_icon_store = {
  namespaced: true,
  state: {
    id: '',
    like: '',
    entity: '',
  },
  mutations: {
    set_favourite_data(state, payload) {
      state.id = payload.id;
      state.like = payload.like;
      state.entity = payload.entity;
    },
  },
  actions: {
    favourite_data_api({ commit }, payload) {
      if (
        payload.fav_related_media === undefined ||
        payload.fav_related_media === null
      ) {
        delete payload.fav_related_media;
      }
      commit('set_favourite_data', payload);
      return new Promise((resolve, reject) => {
        axios
          .post(APIs.favourite_api, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },
  },
};

export default fav_card_icon_store;
