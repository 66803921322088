export const all_entity_type_values = {
  character: {
    entity_type_key: 'character',
    component: 'describe',
    singular: 'Character',
    plural: 'Characters',
    card_component: 'CharacterCardComponent',
    satisfy_text: 'as a character appears in the following media titles:',
  },
  place: {
    entity_type_key: 'place',
    component: 'describe',
    singular: 'Place',
    plural: 'Places',
    card_component: 'PlaceCardComponent',
    satisfy_text: 'as a place appears in the following media titles:',
  },
  item: {
    entity_type_key: 'item',
    component: 'describe',
    singular: 'Item',
    plural: 'Items',
    card_component: 'ThingCardComponent',
    satisfy_text: 'as a item appears in the following media titles:',
  },
  theme: {
    entity_type_key: 'theme',
    component: 'describe',
    singular: 'Theme',
    plural: 'Themes',
    card_component: 'ThemeCardComponent',
    satisfy_text: 'as a theme appears in the following media titles:',
  },
  genre: {
    entity_type_key: 'genre',
    component: 'describe',
    singular: 'Genre',
    plural: 'Genres',
    card_component: 'GenreCardComponent',
    satisfy_text: 'as a genre appears in the following media titles:',
  },
  topic: {
    entity_type_key: 'topic',
    component: 'describe',
    singular: 'Topic',
    plural: 'Topics',
    card_component: 'TopicCardComponent',
    satisfy_text: 'as a topic appears in the following media titles:',
  },
  time_period: {
    entity_type_key: 'time_period',
    component: 'describe',
    singular: 'Time Period',
    plural: 'Time Periods',
    card_component: 'TimePeriodCardComponent',
    satisfy_text: 'as a time period appears in the following media titles:',
  },
  activity: {
    entity_type_key: 'activity',
    component: 'enhance',
    singular: 'Activity',
    plural: 'Activities',
    card_component: 'ActivityCardComponent',
    satisfy_text: 'as a activity appears in the following media titles:',
  },
  career_interest: {
    entity_type_key: 'career_interest',
    component: 'enhance',
    singular: 'Career Interest',
    plural: 'Career Interests',
    card_component: 'CareerInterestCardComponent',
    satisfy_text: 'as a career interest appears in the following media titles:',
  },
  purchase_oppurtunity: {
    entity_type_key: 'purchase_oppurtunity',
    component: 'enhance',
    singular: 'Purchase Opportunity',
    plural: 'Purchase Opportunities',
    card_component: 'PurchaseCardActivityComponent',
    satisfy_text:
      'as a purchase opportunity appears in the following media titles:',
  },
  media_credit: {
    entity_type_key: 'media_credit',
    component: 'none',
    singular: 'Creator',
    plural: 'Creators',
    satisfy_text:
      'as a creater opportunity appears in the following media titles:',
  },
};

// media: {
//     entity_type_key: 'media',
//     singular: 'Media',
//     plural: 'Media'
// }
