export function media_page_navigation(router, id, media_name, media_type) {
  router.push({
    name: 'media-data',
    params: { id: id },
    query: {
      media_name: media_name,
      media_type: media_type,
    },
  });
}
