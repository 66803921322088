export const xp_points = {
  media_type: {
    xp_points: 0,
    xp_points_display: '+0 XP',
  },
  name: {
    xp_points: 1,
    xp_points_display: '+1 XP',
  },
  description: {
    xp_points: 16,
    xp_points_display: '+16 XP',
  },
  image_upload: {
    xp_points: 11,
    xp_points_display: '+11 XP',
  },
  fiction: {
    xp_points: 1,
    xp_points_display: '+1 XP',
  },
  native: {
    xp_points: 6,
    xp_points_display: '+6 XP',
  },
  curators: {
    xp_points: 11,
    xp_points_display: '+11 XP',
  },
  character_traits: {
    xp_points: 6,
    xp_points_display: '+6 XP',
  },
 
  google_map_link: {
    xp_points: 6,
    xp_points_display: '+6 XP',
  },
  google_iframe: {
    xp_points: 6,
    xp_points_display: '+6 XP',
  },
  no_of_participants: {
    xp_points: 6,
    xp_points_display: '+6 XP',
  },
  summary_description: {
    xp_points: 6,
    xp_points_display: '+6 XP',
  },
  roles: {
    xp_points: 6,
    xp_points_display: '+6 XP',
  },
  add_resources: {
    xp_points: 6,
    xp_points_display: '+6 XP',
  },

}
