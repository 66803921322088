<template>
    <div class="w-full mx-auto px-5 font-inter">
        <HomeHeaders />
        <section>
            <div v-if="loading" class="loading-indicator">
                <div class="loader"></div>
            </div>
            <div v-else class="bg-[#e4eaf8] font-inter p-2 px-4">
                <div class="py-6">
                    <h2 class="text-[45px] font-semibold text-center py-6">XP: <span class="text-blue">{{ xp_total }}</span></h2>
                </div>
                <div class="flex items-center font-[600] py-2 mb-10 text-[#1C1C1C] text-[36px] gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                        class="bi bi-clock-history" viewBox="0 0 16 16">
                        <path
                            d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
                        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                        <path
                            d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                    </svg>
                    <p>History</p>
                </div>
                <div class="text-[#1C1C1C] mb-5" v-for="(history, index) in xp_history" :key="index">
                    <p class="font-[600] text-[22px] xs:text-[26px] text-[#1C1C1C] mb-5">{{ history.date }}</p>
                    <div v-for="(detail, i) in history.details" :key="i"
                        class="bg-white p-4 xs:p-6 xs:flex gap-4 justify-between mb-4 items-center rounded-xl">
                        <p class="xs:hidden block text-[14px] mb-2 sm:text-[16px] font-[500] text-gray-500">{{
                            detail.date }}</p>
                        <p class="font-[500] text-[18px] sm:text-[24px]">{{ detail.message }}</p>
                        <p class="text-[12px] xs:block hidden sm:text-[16px] font-[500] text-gray-500">{{ detail.date }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="recently-bottom-border border-b-1 border-borderColor mt-20 mb-4 mx-20"></div>
        </section>

        <HomeFooter />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import HomeHeaders from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';

export default {
    name: 'XPscreen',
    components: {
        HomeHeaders,
        HomeFooter,
    },

    async created() {
        await this.fetchDataFromApis();
    },

    data() {
        return {
            loading: false,
        };
    },

    computed: {
        ...mapState('xp_store', {
            xp_data: (state) => state.xp_data,
        }),

        // Compute XP total
        xp_total() {
            return this.xp_data?.xp || 0;
        },

        // Transform XP history to match the required structure
        xp_history() {
            return this.xp_data?.history?.map((entry) => ({
                date: entry.date,
                details: entry.logs.map((log) => ({
                    message: log,
                    date: entry.date, // Reuse the date for each log
                })),
            })) || [];
        },
    },

    methods: {
        ...mapActions('xp_store', ['xp_api']),

        async fetchDataFromApis() {
            this.loading = true;
            try {
                const response = await this.xp_api();
                console.log('Fetched XP Data:', response.data.result); // Log the fetched data
                // Update the Vuex store with the fetched result
                this.$store.commit('xp_store/updateXpData', response.data.result);
            } catch (error) {
                console.error('Error fetching XP data:', error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
@import '../../css/style.css';
@import '../../css/discovery.css';
</style>