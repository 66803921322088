<template>
  <div class="w-full bg-white shadow-gray-400 shadow-[rgba(0,0,0,0.2)_0px_0px_5px_0px]">
    <HomeHeader />
  </div>
  <div v-if="loading" class="loading-indicator">
    <div class="loader"></div>
  </div>
  <div v-else class="w-full mx-auto my-10 font-inter max-[654px]:px-5 px-10">
    <button
      class="px-10 py-4 max-[414px]:px-4 max-[414px]:py-1 text-blue text-lg font-medium border-1 border-blue rounded-full hover:text-white hover:bg-blue duration-500"
      @click="goBack">
      Back
    </button>
    <h2 class="text-2xl max-[654px]:text-3xl font-bold text-center">
      User Create Form
    </h2>
    <p class="text-base text-[#252525] text-center opacity-60 mt-5 mb-10">
      Any new data you add will only be visible to you until it is reviewed and
      approved by our curator team. This ensures the quality and accuracy of
      information on our platform.
    </p>

    <div class="mx-auto w-[976px] max-[1023px]:w-full pb-2 my-8 max-[1023px]:px-5">
      <div class="flex justify-between items-center mb-6 max-[1023px]:mb-3">
        <div class="sm:text-2xl text-xl font-semibold">
          XP: <span class="text-blue ">{{ currentXP }}</span>
        </div>
        <div class=" text-2xl sm:text-4xl text-blue  font-semibold">
          XP Points
        </div>
        <div class="sm:text-2xl text-xl font-semibold">
          XP: <span class="text-blue ">{{ totalPossibleXP }}</span>
        </div>
      </div>

      <div class="relative bg-[#d6dcf4] mb-3 h-9 w-full rounded-full overflow-hidden">
        <div class="absolute h-full bg-blue rounded-full transition-all duration-300 ease-in-out"
          :style="{ width: progressPercentage + '%' }">
        </div>
      </div>
    </div>

    <form>
      <div class="w-full mb-10 relative">
        <span
          class="absolute top-[63px] right-[30px] max-[640px]:top-[52px] text-[#ADB5BD] icon-updownarrow pointer-events-none"></span>
        <label for="dataType" class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg">Media Type<span
            class="text-red-500">*</span></label>
        <select @change="selected_media_type_func($event)"
          class="border-1 border-borderColor rounded-full text-[#53585c] text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2 appearance-none"
          id="dataType">
          <option v-for="(value, key, index) in media_type_options.result" :key="index" :value="key">
            {{ value }}
          </option>
        </select>
      </div>
      <div class="w-full mb-9">
        <div class="flex justify-between">
          <label for="name" class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg">Title<span
              class="text-red-500">*</span></label>
          <p class="mr-3 text-xl italic">{{ xp_points.title.xp_points_display }}</p>
        </div>
        <input v-model="title" type="text"
          class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
          id="name" placeholder="" />
      </div>
      <div v-if="selected_media_type !== 'theater'" class="w-full mb-9">
        <div class="flex justify-between">
          <label for="identifier" class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg">{{ identifier
            }}<span class="text-red-500">*</span></label>
          <p class="mr-3 text-xl italic">{{ xp_points.ISBN.xp_points_display }}</p>
        </div>
        <input v-model="identifier_text" type="text"
          class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
          id="identifier" placeholder="" />
      </div>
      <div class="w-full mb-9">
        <div class="flex justify-between">
          <label for="participants" class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg">Origin<span
              class="text-red-500">*</span></label>
          <p class="mr-3 text-xl italic">{{ xp_points.origin.xp_points_display }}</p>
        </div>
        <input v-model="origin" type="number"
          class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
          id="name" placeholder="" />
      </div>

      <div class="w-full mb-10 relative">
        <span
          class="absolute top-[63px] right-[30px] max-[640px]:top-[52px] text-[#ADB5BD] icon-updownarrow pointer-events-none"></span>
        <div class="flex justify-between">
          <label for="dataType" class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg">Format<span
              class="text-red-500">*</span></label>
              <p class="mr-3 text-xl italic">{{ xp_points.format.xp_points_display }}</p>

        </div>
        <select @change="selected_format_func($event)"
          class="border-1 border-borderColor rounded-full text-[#53585c] text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2 appearance-none"
          id="dataType">
          <option v-for="(value, key, index) in media_format_options.result" :key="index" :value="key">
            {{ value }}
          </option>
        </select>
      </div>

      <div class="flex flex-col items-end justify-center w-full h-64 mb-10">
        <template v-if="!show_avatar">
          <p class="mr-3 text-xl italic">{{ xp_points.img.xp_points_display }}</p>
          <label for="dropzone-file"
            class="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500">
            <div class="flex flex-col items-center justify-center pt-5 pb-6">
              <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
              </svg>
              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span class="font-semibold">Click to upload Image</span>
              </p>
              <p class="text-xs text-gray-500 dark:text-gray-400">
                PNG, JPG (MAX. 1 MB)
              </p>
            </div>
            <input id="dropzone-file" type="file" class="hidden" @change="handleAvatarUpload"
              accept=".png, .jpg, .jpeg" />
          </label>
        </template>

        <!-- Image Preview -->
        <template v-else>
          <div class="relative w-full h-full">
            <img :src="show_avatar" alt="Preview" class="w-full h-full object-cover border border-gray-300 rounded-lg"
              style="object-fit: contain; object-position: center" />
            <!-- Clear Image Button -->
            <span @click="deleteAvatar" class="absolute top-2 right-2 text-red-600 px-2 py-1 cursor-pointer">
              Clear
            </span>
          </div>
        </template>
      </div>

      <div class="w-full mb-9">
        <div class="flex justify-between">
          <label for="description" class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg">Summary</label>
          <p class="mr-3 text-xl italic">{{ xp_points.summary.xp_points_display }}</p>
        </div>
        <textarea v-model="summary" id="description" rows="5" cols="30"
          class="border-1 border-borderColor rounded-3xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"></textarea>
      </div>
      <div class="w-full mb-9">
        <div class="flex justify-between">
          <label for="link" class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg">URL Link</label>
          <p class="mr-3 text-xl italic">{{ xp_points.URL_Link.xp_points_display }}</p>
        </div>
        <input v-model="summary_link" type="text"
          class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
          id="name" placeholder="" />
      </div>

      <div
        class="flex flex-col border-1 border-borderColor rounded-3xl px-8 py-6 mb-5 max-[654px]:px-3 max-[654px]:py-3">
        <div class="flex justify-between">
          <h3 class="text-xl font-bold mb-3">Target Audience</h3>
          <p class="mr-3 text-xl italic">{{ xp_points.target_audiance.xp_points_display }}</p>
        </div>
        <div class="flex relative mb-5">
          <input v-model="search_target" type="text"
            class="border-1 border-borderColor rounded-full w-full text-base text-[#ADB5BD] pl-6 py-3"
            placeholder="Search Target Audience..." />
          <div class="absolute top-0 right-0"></div>
        </div>
        <div class="h-36 overflow-x-auto">
          <div v-for="(label, key) in filteredTargetAudienceOptions" :key="key"
            class="px-5 py-2 mb-1 rounded-full hover:bg-badgeBG">
            <label class="flex items-center">
              <input type="checkbox" :id="'trait-' + key" :value="key" v-model="selected_target_audience_list"
                class="w-5 h-5 mr-3" />
              <span>{{ label }}</span>
            </label>
          </div>
        </div>
      </div>

      <div class="flex justify-between max-[654px]:flex-col">
        <div class="">
          <p class="mr-3 text-center text-xl italic">{{ xp_points.type.xp_points_display }}</p>
          <div class="flex items-center max-[654px]:justify-between">
            <p class="mr-4 text-lg font-medium text-gray-900 dark:text-gray-300">
              Real / Fiction
            </p>
            <label class="relative cursor-pointer">
              <input v-model="real_fiction" type="checkbox" class="sr-only peer" />
              <div
                class="w-11 h-6 flex items-center bg-gray-300 rounded-full peer peer-checked:after:translate-x-full after:absolute after:left-[2px] peer-checked:after:border-white after:bg-white after:border after:border-gray-300 after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue">
              </div>
            </label>
          </div>
        </div>
        <div>
          <p class="mr-3 text-center text-xl italic">{{ xp_points.type.xp_points_display }}</p>
          <div class="flex items-center max-[654px]:justify-between">
            <p class="mr-4 text-lg font-medium text-gray-900 dark:text-gray-300">
              Submit to Curators
            </p>
            <label class="relative cursor-pointer">
              <input v-model="submit_curator" type="checkbox" class="sr-only peer" />
              <div
                class="w-11 h-6 flex items-center bg-gray-300 rounded-full peer peer-checked:after:translate-x-full after:absolute after:left-[2px] peer-checked:after:border-white after:bg-white after:border after:border-gray-300 after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue">
              </div>
            </label>
          </div>
        </div>
      </div>
      <p class="text-sm text-[#8f8f8f] text-center my-7">
        Submitting spam, offensive content, or any other unwanted data to the
        curators for review may result in action taken against your account,
        including but not limited to temporary or permanent suspension of
        access.
      </p>
      <div class="w-full mb-9">
        <div class="flex justify-between">
          <label for="additional_resources"
            class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg">Additional
            Resources</label>
          <p class="mr-3 text-xl italic">{{ xp_points.type.xp_points_display }}</p>
        </div>
        <div v-for="(resource, index) in additionalResources" :key="index" class="relative flex items-center mt-2">
          <input type="text"
            class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 max-[640px]:px-4 max-[640px]:py-2"
            :placeholder="'Resource ' + (index + 1)" v-model="additionalResources[index]" />
          <!-- Remove button -->
          <button type="button" @click="removeResource(index)" class="absolute right-2 text-red-500 text-2xl ml-3">
            -
          </button>
        </div>
        <button type="button" @click="addResource" class="text-blue-500 text-xl mt-2 flex items-center">
          <span class="mr-1">+</span> Add Resource
        </button>
      </div>
    </form>

    <div class="flex">
      <button @click.prevent="submit_form()"
        class="border-1 rounded-full border-blue bg-blue px-12 py-3 max-[376px]:px-7 max-[376px]:py-1 text-4xl max-[640px]:text-2xl text-white font-medium hover:border-1 hover:bg-white hover:border-blue hover:text-blue duration-500">
        Submit
      </button>
    </div>
  </div>
  <!-- Footer Start -->
  <HomeFooter />
  <!-- Footer End -->
</template>

<script>
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import { media_page_navigation } from '@/common_components/common_routing/media_page_routing';
import { mapState, mapActions } from 'vuex';
import { all_media_type_values } from '@/common_variables/media_type';
import { create_form_xp } from '@/common_variables/create_form_xp';

export default {
  name: 'CreateNewMedia',
  components: {
    HomeHeader,
    HomeFooter,
  },

  async created() {
    await this.fetchDataFromApis();
  },

  computed: {

    totalPossibleXP() {
      let total = 0;

      // Add all possible XP points
      total += this.xp_points.title.xp_points; // Title
      total += this.xp_points.ISBN.xp_points; // ISBN/Identifier
      total += this.xp_points.origin.xp_points; // Origin
      total += this.xp_points.format.xp_points; // Format
      total += this.xp_points.img.xp_points; // Image
      total += this.xp_points.summary.xp_points; // Summary
      total += this.xp_points.URL_Link.xp_points; // URL Link
      total += this.xp_points.another_source.xp_points; // Additional Resources
      total += this.xp_points.target_audiance.xp_points; // Target Audience
      total += this.xp_points.type.xp_points * 2; // Real/Fiction + Submit to Curators (type XP used for both)

      // Only add identifier XP if not theater type
      if (this.selected_media_type !== 'theater') {
        if (this.selected_media_type === 'museum') {
          total += this.xp_points.meusium_no.xp_points;
        } else if (this.selected_media_type === 'product') {
          total += this.xp_points.upc.xp_points;
        } else {
          total += this.xp_points.ISBN.xp_points;
        }
      }

      return total;
    },

    currentXP() {
      let total = 0;

      // Title XP
      if (this.title.trim()) {
        total += this.xp_points.title.xp_points;
      }

      // Identifier XP (ISBN/UPC/Museum Number)
      if (this.identifier_text.trim() && this.selected_media_type !== 'theater') {
        total += this.xp_points.ISBN.xp_points;
      }

      // Origin XP
      if (this.origin !== '') {
        total += this.xp_points.origin.xp_points;
      }

      // Format XP
      if (this.selected_format) {
        total += this.xp_points.format.xp_points;
      }

      // Image XP
      if (this.image) {
        total += this.xp_points.img.xp_points;
      }

      // Summary XP
      if (this.summary.trim()) {
        total += this.xp_points.summary.xp_points;
      }

      // URL Link XP
      if (this.summary_link.trim()) {
        total += this.xp_points.URL_Link.xp_points;
      }

      // Target Audience XP
      if (this.selected_target_audience_list.length > 0) {
        total += this.xp_points.target_audiance.xp_points;
      }

      // Additional Resources XP
      if (this.additionalResources.length > 0 && this.additionalResources[0].trim()) {
        total += this.xp_points.another_source.xp_points;
      }

      // Real/Fiction XP
      if (this.real_fiction) {
        total += this.xp_points.type.xp_points;
      }

      // Submit to Curator XP
      if (this.submit_curator) {
        total += this.xp_points.type.xp_points;
      }

      return total;
    },

    progressPercentage() {
      if (this.totalPossibleXP === 0) return 0;
      return Math.min((this.currentXP / this.totalPossibleXP) * 100, 100);
    },


    ...mapState({
      media_type_options: (state) =>
        state.create_new_media_store.media_type_options,
      target_audience_options: (state) =>
        state.create_new_media_store.target_audience_options,
      media_format_options: (state) =>
        state.create_new_media_store.media_format_options,
    }),
    identifier() {
      return all_media_type_values[this.selected_media_type][
        'identifier_label'
      ];
    },

    filteredTargetAudienceOptions() {
      const query = this.search_target.toLowerCase();
      return Object.fromEntries(
        Object.entries(this.target_audience_options.result).filter(([label]) =>
          label.toLowerCase().includes(query),
        ),
      );
    },
  },

  data() {
    return {
      selected_media_type: all_media_type_values['book']['media_type_key'],
      selected_format: '',
      selected_target_audience_list: [],
      search_target: '',
      real_fiction: false,
      title: '',
      summary: '',
      summary_link: '',
      submit_curator: false,
      origin: '',
      format: '',
      show_avatar: null,
      loading: true,
      image: '',
      additionalResources: [],
      identifier_text: '',
      xp_points: create_form_xp,
      currentProgress: 0,
      totalXP: 0,
    };
  },

  methods: {
    ...mapActions('create_new_media_store', [
      'get_media_type_options_api',
      'add_target_audience_options_api',
      'add_media_form_api',
      'get_media_format_options_api',
    ]),

    calculateTotalXP() {
      let total = 0;

      // Add base XP for required fields
      total += this.name ? this.xp_points.name : 0;
      total += this.selected_media_type ? this.xp_points.data_type : 0;

      // Add XP for description if applicable
      if (!this.not_description.includes(this.selected_media_type) && this.description) {
        total += this.xp_points.description;
      }

      // Add XP for participants if activity
      if (this.selected_media_type === this.all_entity_values['activity']['entity_type_key'] && this.no_of_participants) {
        total += this.xp_points.participants;
      }

      // Add XP for place-specific fields
      if (this.selected_media_type === this.all_entity_values['place']['entity_type_key']) {
        if (this.google_map_link) total += this.xp_points.google_map;
        if (this.google_embed_iframe) total += this.xp_points.google_embed;
      }

      // Add XP for media credit fields
      if (this.selected_media_type === this.all_entity_values['media_credit']['entity_type_key']) {
        if (this.summary_description) total += this.xp_points.summary;
        if (this.selected_role) total += this.xp_points.role;
      }

      // Add XP for character traits
      if (this.selected_media_type === this.all_entity_values['character']['entity_type_key'] &&
        Object.keys(this.selected_char_trait).length > 0) {
        total += this.xp_points.traits;
      }

      // Add XP for image
      if (this.image) {
        total += this.xp_points.image;
      }

      // Add XP for additional resources
      if (this.additionalResources.length > 0 && this.additionalResources[0].trim()) {
        total += this.xp_points.additional_resources;
      }

      return total;
    },

    addResource() {
      this.additionalResources.push('');
    },
    removeResource(index) {
      this.additionalResources.splice(index, 1);
    },
    selected_target_func(key) {
      const index = this.selected_target_audience_list.indexOf(key);
      if (index === -1) {
        this.selected_target_audience_list.push(key);
      } else {
        this.selected_target_audience_list.splice(index, 1);
      }
    },

    async selected_media_type_func(event) {
      this.selected_media_type = event.target.value;
      await this.fetch_media_form_api();
    },

    selected_format_func(event) {
      this.selected_format = event.target.value;
    },

    validateInputs() {
      const errors = [];
      if (!this.title.trim()) {
        errors.push('Title cannot be empty.');
      }
      if (this.origin === '') {
        errors.push('Origin cannot be empty.');
      } else if (parseInt(this.origin, 10) < 0) {
        errors.push('Origin cannot be negative.');
      }
      if (this.selected_target_audience_list.length === 0) {
        errors.push('Atleast select one Target Audience.');
      }
      if (
        !this.identifier_text.trim() &&
        this.selected_media_type !==
        all_media_type_values['theater']['media_type_key']
      ) {
        errors.push('Identifier cannot be empty.');
      }
      if (this.summary_link.trim() && !this.isValidURL(this.summary_link)) {
        errors.push('Summary link must be a valid URL.');
      }

      if (
        this.additionalResources.length > 0 &&
        this.additionalResources[0].trim()
      ) {
        for (const url of this.additionalResources) {
          if (!this.isValidURL(url)) {
            errors.push('Addition Resource link must be a valid URL.');
            break; // Exit the loop early if an invalid URL is found
          }
        }
      }

      return errors;
    },

    isValidURL(url) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-zA-Z0-9\\-\\_\\.]+)\\.([a-zA-Z]{2,}))|' + // domain name
        'localhost|' + // localhost
        '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IPv4
        '\\[([a-fA-F0-9:]+)\\])' + // IPv6
        '(\\:\\d+)?(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?' + // port and path
        '(\\?[;&a-zA-Z0-9%_\\+.~#?&//=]*)?' + // query string
        '(\\#[-a-zA-Z0-9_]*)?$',
        'i',
      ); // fragment locator
      return pattern.test(url);
    },

    navigation_media_page(id, media_name, media_type) {
      media_page_navigation(this.$router, id, media_name, media_type);
    },

    async submit_form() {
      const errors = this.validateInputs();
      if (errors.length > 0) {
        this.$toast.open({
          message: errors.join(' '),
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }

      let from_data_body = {
        media_type: this.selected_media_type,
        o_type: this.real_fiction ? 'fiction' : 'real',
        title: this.title,
        submit_to_curator: this.submit_curator,
        target_audience: this.selected_target_audience_list,
        origin: this.origin,
        format: this.selected_format,
        additional_resource: this.additionalResources,
        identifier: this.identifier_text,
        xp_points: this.currentXP
      };

      // console.log('Form submission data with XP:', from_data_body);

      if (this.summary_link.trim()) {
        from_data_body['summary_link'] = this.summary_link;
      }

      if (this.summary.trim()) {
        from_data_body['summary'] = this.summary;
      }

      if (
        this.selected_media_type ===
        all_media_type_values['theater']['media_type_key']
      ) {
        delete from_data_body['identifier'];
      }

      if (this.image !== '') {
        from_data_body['cover_image'] = this.image;
      }

      this.loading = true;
      await Promise.all([
        this.add_media_form_api(from_data_body)
          .then((response) => {
            this.$toast.open({
              message: 'Form Submitted',
              type: 'success',
              duration: 1000 * 5,
              dismissible: true,
            });
            this.navigation_media_page(
              response.result.id,
              response.result.title,
              response.result.media_type,
              response.result.media_type_display,
            );
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              type: 'error',
              duration: 1000 * 5,
              dismissible: true,
            });
          }),
      ]);
      this.loading = false;
    },

    async fetchDataFromApis() {
      this.loading = true;
      try {
        await Promise.all([
          this.get_media_type_options_api(),
          this.add_target_audience_options_api(),
          this.get_media_format_options_api({
            media_type: this.selected_media_type,
          }),
        ]);
        this.selected_format = Object.keys(this.media_format_options.result)[0];
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },

    async fetch_media_form_api() {
      try {
        await Promise.all([
          this.get_media_format_options_api({
            media_type: this.selected_media_type,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      }
    },

    handleAvatarUpload(event) {
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.$toast.open({
          message: 'Profile picture must be less than 1 MB',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.show_avatar = reader.result;
        this.image = reader.result;
      };
      reader.readAsDataURL(file);
    },

    deleteAvatar() {
      this.image = '';
      this.show_avatar = null;
    },

    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    // Watch for changes in XP and update progress
    currentXP: {
      handler(newValue) {
        this.currentProgress = this.progressPercentage;
        this.totalXP = newValue;
      },
      immediate: true
    }
  }
};
</script>

<style></style>
