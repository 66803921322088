import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const entity_store = {
  namespaced: true,
  state: {
    entity_result: {},
    entity_result_list: {},
    group_result: {},
  },
  mutations: {
    set_entity_result(state, { data, entity_type }) {
      state.entity_result[entity_type] = data;
    },
    set_group_result(state, { data, entity_type }) {
      state.group_result[entity_type] = data;
    },
    set_entity_result_list(state, { data, entity_type }) {
      if (!state.entity_result_list[entity_type]) {
        state.entity_result_list[entity_type] = [];
      }
      state.entity_result_list[entity_type] =
        state.entity_result_list[entity_type].concat(data);
    },
    reset_entity_result(state, { entity_type }) {
      console.log('reset_entity_result entity type: ', entity_type);
      state.entity_result_list[entity_type] = [];
    },
  },
  actions: {
    entity_show_result_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const apiUrl = `${APIs.entity_api.replace('{id}', payload.id)}${
          payload.entity_type
        }?${
          payload.entity_type !== 'purchase_oppurtunity'
            ? 'per_page=2'
            : 'per_page=4'
        }&page=${payload.page}${
          payload.selected_category ? `&group=${payload.selected_category}` : ''
        }`;

        axios
          .get(apiUrl)
          .then((response) => {
            commit('set_entity_result', {
              data: response.data.result,
              entity_type: payload.entity_type,
            });
            commit('set_entity_result_list', {
              data: response.data.result.results,
              entity_type: payload.entity_type,
            });
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting entity_show_result_api API:', error);
            reject(error);
          });
      });
    },

    group_result_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${APIs.group_api.replace('{id}', payload.id)}?entity_type=${
              payload.entity_type
            }`,
          )
          .then((response) => {
            commit('set_group_result', {
              data: response.data,
              entity_type: payload.entity_type,
            });
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting feature_media_api API:', error);
            reject(error);
          });
      });
    },
  },
};

export default entity_store;
