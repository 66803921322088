import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const journal_store = {
  namespaced: true,
  state: {
    journal_media_data: null,
  },
  mutations: {
    set_journal_media_data(state, data) {
      state.journal_media_data = data;
    },
  },

  actions: {
    journal_media_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.journal_media_api)
          .then((response) => {
            commit('set_journal_media_data', response.data);
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting another API:', error);
            reject(error);
          });
      });
    },
  },
};

export default journal_store;
