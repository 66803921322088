<template>
  <section class="w-full  p-5">
    <EntityCardComponent
      v-for="(key, index) in filtered_entity_type_display"
      :key="index"
      :entity_key="key['entity_type_key']"
    />
    <RelatedMediaComponent />
    <!-- Related Media End -->
  </section>
  <!-- Describe Section End -->
</template>

<script>
// Import jQuery and Slick slider

import { all_entity_type_values } from '@/common_variables/entity_type';
import EntityCardComponent from '@/controller/media_data/components/common_component/entity_component/entity_card.vue';
import RelatedMediaComponent from '@/controller/media_data/components/describe_components/related_media/related_media_component.vue';

export default {
  name: 'DescribeComponent',
  components: {
    EntityCardComponent,
    RelatedMediaComponent,
  },
  computed: {
    filtered_entity_type_display() {
      let filter_entity = Object.values(all_entity_type_values).filter(
        (entity) => entity.component === 'describe',
      );
      return filter_entity;
    },
  },

  data() {
    return {
      isLogin: false,
      tab_class: {
        underline_tab:
          'tab-button text-2xl text-blue border-b-2 pb-1 border-blue max-[767px]:ml-0 ml-7',
        without_underline_tab:
          'tab-button text-2xl text-black/40 max-[767px]:ml-0 ml-7',
      },
    };
  },

  mounted() {
    const token = localStorage.getItem('token');
    if (token === undefined || token === null || token === '') {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }
  },
};
</script>

<style scoped>
.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated-background {
  height: 400px;
  width: 90%;
  background-color: #f0f0f0;
  position: relative;
}

.background-masker {
  position: absolute;
}

.related-media-slider,
.related-media-activities-slider .career-interests-slider {
  overflow: hidden;
}

.slick-track {
  display: flex !important;
  padding: 5px 0;
}

/* the slides */
.slick-slide {
  margin: 0 20px;
  height: inherit !important;
}

/* the parent */
.slick-list {
  margin: 0 -20px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  transform: translateY(-200px);
}
</style>
