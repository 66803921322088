import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const home_search_store = {
  namespaced: true,
  state: {
    home_search_result: null,
    show_result: [],
    groups_result: [],
  },
  mutations: {
    set_groups_result(state, data) {
      state.groups_result = data;
    },

    set_home_search_result(state, data) {
      state.home_search_result = data;
    },

    show_more_results(state, data) {
      state.show_result = data;
    },

    reset_home_search_result_list(state) {
      state.media_result = [];
    },
    
    reset_home_search_result(state) {
      state.home_search_result = null;
    },
  },
  actions: {
    home_search_result_api({ commit }, payload) {
      // Construct query parameters
      const params = new URLSearchParams();
      
      // Add search query if exists
      if (payload.search_query) {
        params.append('q', payload.search_query);
      }
      
      // Add filters if they exist
      if (payload.filters && payload.filters.length > 0) {
        params.append('filters', payload.filters.join(','));
      }
      
      // Add entity_type if it exists
      if (payload.entity_type) {
        params.append('entity', payload.entity_type);
      }

      // Construct the URL with all parameters
      const url = `${APIs.home_search_api}?${params.toString()}`;
      console.log('URL:', url);
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            commit('set_home_search_result', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response?.data?.result || error);
          });
      });
    },

    groups_result_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.groups_api)
          .then((response) => {
            commit('set_groups_result', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response?.data?.result || error);
          });
      });
    },

    show_more_results_api({ commit }, payload) {
      const params = new URLSearchParams({
        q: payload.query,
        entity: payload.entity_type,
        page: payload.page,
        filters: payload.filters.join(','),

      });

      return new Promise((resolve, reject) => {
        axios
          .get(`${APIs.home_search_api}?${params.toString()}`)
          .then((response) => {
            commit('show_more_results', response.data.result);
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting feature_media_api API:', error);
            reject(error);
          });
      });
    },

    satisfy_curiosity_option_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APIs.satisfy_curiosity_option_api}?q=${payload.query}`)
          .then((response) => {
            commit('set_satisfy_curiosity_option', response.data.result);
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting feature_media_api API:', error);
            reject(error);
          });
      });
    },
  },
};

export default home_search_store;