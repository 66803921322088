import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const home_store = {
  namespaced: true,
  state: {
    recently_viewed_data: null,
    trending_media_data: null,
    feature_media_data: null,
    favorite_data: null,
    search_home_data: null,
    favorite_data_list: [],
    selected_tab: 'identify',
    show_search: false,
    badge_curoisity_data: null,
  },
  mutations: {
    set_show_search(state, show_search) {
      state.show_search = show_search;
    },
    set_selected_tab(state, tab) {
      state.selected_tab = tab;
    },
    set_recently_viewed_data(state, data) {
      state.recently_viewed_data = data;
    },
    set_trending_media_data(state, data) {
      state.trending_media_data = data;
    },
    set_feature_media_data_data(state, data) {
      state.feature_media_data = data;
    },
    set_favorite_data(state, data) {
      state.favorite_data = data;
    },
    set_search_home_data(state, data) {
      state.search_home_data = data;
    },
    set_favorite_data_list(state, data) {
      state.favorite_data_list = data;
    },
    set_concat_favorite_data_list(state, data) {
      state.favorite_data_list = state.favorite_data_list.concat(data);
    },
    set_badge_curoisity_data(state, data) {
      state.badge_curoisity_data = data;
    },
    reset_search_home_results_api(state) {
      state.search_home_data = null;
    },
  },

  actions: {
    search_home_results_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.home_search_api + '?q=' + payload.search_query)
          .then((response) => {
            commit('set_search_home_data', response.data);
            resolve(response);
          })
          console.log("home store", )
          .catch((error) => {
            console.error('Error hitting feature_media_api API:', error);
            reject(error);
          });
      });
    },

    all_favorite_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.all_favorite_api + '?page=' + String(payload.page))
          .then((response) => {
            commit('set_favorite_data', response.data);
            if (payload.page === 1) {
              commit('set_favorite_data_list', response.data.result.results);
            } else {
              commit(
                'set_concat_favorite_data_list',
                response.data.result.results,
              );
            }
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting feature_media_api API:', error);
            reject(error);
          });
      });
    },

    recently_viewed_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.recent_views_api)
          .then((response) => {
            commit('set_recently_viewed_data', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    trending_media_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.trending_media_api)
          .then((response) => {
            commit('set_trending_media_data', response.data);
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting another API:', error);
            reject(error);
          });
      });
    },

    feature_media_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.feature_media_api)
          .then((response) => {
            commit('set_feature_media_data_data', response.data);
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting feature_media_api API:', error);
            reject(error);
          });
      });
    },

    badge_curoisity_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.badge_curoisity_api)
          .then((response) => {
            commit('set_badge_curoisity_data', response.data.result);
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting set_badge_curoisity_data API:', error);
            reject(error);
          });
      });
    },

    profile_api() {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.profile_api)
          .then((response) => {
            localStorage.setItem('profile_data', JSON.stringify(response.data));
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting feature_media_api API:', error);
            reject(error);
          });
      });
    },
  },
};

export default home_store;
