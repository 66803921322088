export const create_form_xp = {
    title: {
      xp_points: 1,
      xp_points_display: '+1 XP',
    },
    ISBN: {
      xp_points: 1,
      xp_points_display: '+1 XP',
    },
    origin: {
      xp_points: 1,
      xp_points_display: '+1 XP',
    },
    format: {
      xp_points: 6,
      xp_points_display: '+6 XP',
    },
    img: {
      xp_points: 11,
      xp_points_display: '+11 XP',
    },
    summary: {
      xp_points: 16,
      xp_points_display: '+16 XP',
    },
    URL_Link: {
      xp_points: 1,
      xp_points_display: '+1 XP',
    },
    another_source: {
      xp_points: 6,
      xp_points_display: '+6 XP',
    },
    target_audiance: {
      xp_points: 3,
      xp_points_display: '+3 XP',
    },
    type: {
      xp_points: 1,
      xp_points_display: '+1 XP',
    },
    upc: {
      xp_points: 1,
      xp_points_display: '+1 XP',
    },
    meusium_no: {
      xp_points: 1,
      xp_points_display: '+1 XP',
    },
  }