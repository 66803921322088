import { createStore } from 'vuex';
import create_account_store from '@/controller/create_account/create_account_store';
import login_account_store from '@/controller/login_account/login_account_store';
import home_logout_store from '@/controller/home_logout/home_logout_store';
import home_store from '@/controller/home/home_store';
import header_store from '@/common_components/header/header_store';
import search_result_store from '@/controller/search_result/search_result_store';
import profile_setting_store from '@/controller/profile_setting/profile_setting_store';
import specific_data_store from '@/controller/specific_data/specific_data_store';
import search_satisfy_store from '@/controller/search_satisfy/search_satisfy_store';
import create_new_media_store from '@/controller/create_new_media/create_new_media_store';
import home_search_store from '@/controller/home_search/home_search_store';
import review_quote_store from '@/controller/review_quote/review_quote_store';
import add_quote_store from '@/controller/add_quotes/add_quote_store';
import add_missing_info_store from '@/controller/add_missing_info/add_missing_info_store';
import see_all_media_store from '@/controller/see_all_media/see_more_media_store';
import related_media_store from '@/controller/media_data/components/describe_components/related_media/related_media_store';
import related_activity_media_store from '@/controller/media_data/components/enhance/related_activity_media/related_media_store';
import see_all_entity_store from '@/controller/see_all_media/see_more_media_store';
import showoff_initial_result_store from '@/controller/showoff_initial_result/showoff_initial_result_store';
import engage_store from '@/controller/media_data/components/engage_component/engage_store';
import related_media_activity_store from '@/controller/media_data/components/enhance/related_activity_media/related_media_store';
import fav_card_icon_store from '@/common_components/fav_card_icon/fav_card_icon_store';
import identify_store from '@/controller/media_data/components/identify_component/identify_store';
import experience_store from '@/controller/media_data/components/experience_component/experience_store';
import entity_store from '@/controller/media_data/components/common_component/entity_component/entity_card_store';
import journal_store from '@/controller/journal/journal_store';
import stats_store from '@/controller/stats/stats_store';
import xp_store from '@/controller/xp_page/xp_store';

export default createStore({
  modules: {
    create_account_store: create_account_store,
    login_account_store: login_account_store,
    home_logout_store: home_logout_store,
    home_store: home_store,
    header_store: header_store,
    search_result_store: search_result_store,
    profile_setting_store: profile_setting_store,
    specific_data_store: specific_data_store,
    search_satisfy_store: search_satisfy_store,
    create_new_media_store: create_new_media_store,
    home_search_store: home_search_store,
    review_quote_store: review_quote_store,
    add_quote_store: add_quote_store,
    add_missing_info_store: add_missing_info_store,
    see_all_media_store: see_all_media_store,
    related_media_store: related_media_store,
    related_activity_media_store: related_activity_media_store,
    see_all_entity_store: see_all_entity_store,
    showoff_initial_result_store: showoff_initial_result_store,
    engage_store: engage_store,
    related_media_activity_store: related_media_activity_store,
    fav_card_icon_store: fav_card_icon_store,
    identify_store: identify_store,
    experience_store: experience_store,
    entity_store: entity_store,
    journal_store: journal_store,
    stats_store: stats_store,
    xp_store: xp_store,
  },
});
