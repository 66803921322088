<!-- First create a new component: BadgeModal.vue -->
<template>
    <div v-if="isOpen && badgeData.is_active" class="modal-wrapper">
      <!-- Modal backdrop -->
      <div class="fixed inset-0 bg-black bg-opacity-50" @click="closeModal"></div>
      
      <!-- Modal content -->
      <div class="fixed inset-0 flex items-center justify-center z-50">
        <div class="bg-white rounded-3xl p-12 max-w-md w-full mx-4 relative">
          <!-- Close button -->
          <button 
            @click="closeModal"
            class="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
          >
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          
          <!-- Badge content -->
          <div class="flex flex-col items-center">
            <div class=" mb-4">
              <img 
                :src="badgeData.image" 
                :alt="badgeData.title"
                class="w-full h-full object-contain"
              />
            </div>
            
            <h2 class="text-2xl text-blue font-bold text-center mb-2">
              Congratulations!
            </h2>

            <div class="text-center font-semibold text-xl py-4">
                <p>You have completed this badge on {{ badgeData.received_on }} and unlock a new achievement</p>
            </div>
            
            <!-- <h3 class="text-xl font-semibold text-center text-blue-600 mb-4">
              {{ badgeData.title }}
            </h3> -->
            
            <p class="text-center text-gray-600">
              {{ badgeData.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BadgeModal',
    props: {
      isOpen: {
        type: Boolean,
        required: true
      },
      badgeData: {
        type: Object,
        required: true
      }
    },
    methods: {
      closeModal() {
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  </style>