<template>
  <div
    class="shadow-[rgba(0,0,5,0.1)_0px_1px_5px_2px] rounded-3xl cursor-pointer md:h-[150px]"
    @click="review_quote_navigaton(card_data.id, card_data.media_type)"
  >
    <div class="sm:flex">
      <!-- Image -->
      <div class="flex justify-center items-center sm:flex sm:flex-col">
        <img
          :src="card_data.cover_image || defaultImage"
          :alt="card_data.title"
          class="max-w-[150px] max-h-[150px] rounded-xl max-[767px]:mt-3"
        />
      </div>

      <!-- Content -->
      <div class="px-3 sm:px-6 py-3 w-full">
        <!-- Media Type Badge -->
        <div class="flex justify-center sm:justify-end mb-4 items-center">
          <span
            class="bg-blue px-5 py-1 text-white text-base text-center rounded-full"
          >
            {{ card_data.media_type_display || 'Unknown' }}
          </span>
        </div>

        <!-- Title -->
        <div>
          <p
            class="text-xl sm:text-3xl max-[768px]:text-2xl text-center font-bold md:truncate md:w-[380px]"
          >
            {{ card_data.title || 'No Title Available' }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'JournalCard',
  props: {
    card_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLogin: false,
      defaultImage: require('@/images/default-placeholder.jpg'), // Fallback image
    };
  },
  mounted() {
    const token = localStorage.getItem('token');
    this.isLogin = token && token !== '';
  },
  methods: {
    ...mapActions('fav_card_icon_store', ['favourite_data_api']),
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),

    review_quote_navigaton() {
      this.$router.push({
        name: 'review-quote',
        params: {
          id: this.card_data.id,
          media_name: this.card_data.title,
        },
      });
    },
  },
};
</script>

<style scoped>
/* Add any additional styles for the journal card component if needed */
</style>
