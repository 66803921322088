<template>
  <div class="p-5">
    <div class="flex items-center">
      <button class="flex justify-center items-center bg-blue p-5">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.3333 2.66667V21.3333H2.66667V2.66667H21.3333ZM21.3333 0H2.66667C1.2 0 0 1.2 0 2.66667V21.3333C0 22.8 1.2 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8 0 21.3333 0Z"
            fill="white"
          />
          <path
            d="M14.6663 18.6693H5.33301V16.0026H14.6663V18.6693ZM18.6663 13.3359H5.33301V10.6693H18.6663V13.3359ZM18.6663 8.0026H5.33301V5.33594H18.6663V8.0026Z"
            fill="white"
          />
        </svg>
        <span class="text-xl text-white ml-3">Journey System</span>
      </button>
    </div>
    <h2 class="text-[40px] text-blue font-bold my-16">Journey System</h2>
    <p
      v-if="challange_loading === false && challange_result_list.length > 0"
      class="text-2xl font-bold mb-10"
    >
      This media was recommended for the following challenges:
    </p>
    <div
      class="grid max-[767px]:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5"
    >
      <template v-if="challange_loading === false">
        <template v-if="challange_result_list.length > 0">
          <div
            v-for="(challenge, index) in challange_result_list"
            :key="index"
            class="flex-col"
          >
            <div
              class="relative flex justify-center items-center h-[99px] shadow-[rgba(0,0,5,0.1)_0px_1px_5px_2px] rounded-tl-3xl rounded-tr-3xl"
            >
              <div class="absolute left-0 top-0 z-10">
                <img
                  src="../../../../images/radial-shadow.png"
                  alt="Radial Shadow"
                />
              </div>
              <!-- <div
                class="absolute right-3 top-5 z-20 bg-white rounded-full"
              >
                <FavCardIconComponent :card_data="challange_result.results" />
              </div> -->
              <h2 class="relative z-30 text-2xl text-blue font-bold">
                {{ challenge.challenge.label }}
              </h2>
            </div>
          </div>
          <div v-if="show_more_result" class="main-item">
            <div class="animated-background">
              <div class="background-masker btn-divide-left"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <p class="text-2xl font-bold">No data found</p>
        </template>
      </template>
      <template v-else>
        <div class="loading-container">
          <div class="loader"></div>
        </div>
      </template>
    </div>
    <div
      v-if="challange_result?.next !== null"
      class="flex justify-center items-center mt-10"
    >
      <SeeMoreIcon @click="see_more_result(challange_result.next)" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SeeMoreIcon from '@/common_components/show_more/show_more_component.vue';
// import FavCardIconComponent from '@/common_components/fav_card_icon/fav_card_icon_component.vue';
export default {
  name: 'EngageComponent',
  components: {
    SeeMoreIcon,
    // FavCardIconComponent,
  },

  computed: {
    ...mapState({
      challange_result: (state) => state.engage_store.challange_result,
      challange_result_list: (state) =>
        state.engage_store.challange_result_list,
    }),
  },

  async created() {
    this.reset_challange_result_list();
    this.challange_loading = true;
    await Promise.all([this.fetchChallangeDataFromApis(this.page)]);
    this.challange_loading = false;
    console.log(this.challange_result_list);
  },

  data() {
    return {
      challange_loading: true,
      show_more_result: false,
      page: 1,
    };
  },
  methods: {
    ...mapActions('engage_store', ['challange_show_result_api']),
    ...mapMutations('engage_store', ['reset_challange_result_list']),

    async see_more_result(page) {
      if (this.challange_result.next !== null) {
        this.show_more_result = true;
        await this.fetchChallangeDataFromApis(page);
        this.show_more_result = false;
      }
    },

    async fetchChallangeDataFromApis(page) {
      try {
        await Promise.all([
          this.challange_show_result_api({
            id: this.$route.params.id,
            page: page,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      }
    },
  },
};
</script>

<style scoped>
.loading-container {
  display: flex;
}
</style>
