import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const see_all_entity_store = {
  namespaced: true,
  state: {
    see_all_entity_result: null,
    see_all_entity_result_list: [],
    group_result: null,
    current_sort: 'a-z',
  },
  mutations: {
    set_see_all_entity_result(state, data) {
      state.see_all_entity_result = data;
    },
    set_group_result(state, data) {
      state.group_result = data;
    },
    set_see_all_entity_result_list(state, data) {
      state.see_all_entity_result_list =
        state.see_all_entity_result_list.concat(data);
    },
    set_current_sort(state, sortValue) {
      state.current_sort = sortValue;
    },
    reset_see_all_entity_result(state) {
      state.see_all_entity_result = null;
      state.see_all_entity_result_list = [];
    },
  },
  actions: {
    see_all_entity_result_api({ commit }, payload) {
      console.log('payload of see_all is: ', payload);
      return new Promise((resolve, reject) => {
        const orderBy = payload.sort === 'a-z' ? 'name' : '-name';

        const apiUrl = `${APIs.show_all_entities
          .replace('{id}', payload.id)
          .replace('{entity}', payload.entity_key)}?per_page=6&page=${
          payload.page
        }&ob=${orderBy}${
          payload.search_query ? `&q=${payload.search_query}` : ''
        }${
          payload.selected_category ? `&group=${payload.selected_category}` : ''
        }${payload.check_page_route !== undefined ? '&ob=-ct' : ''}`;

        axios
          .get(apiUrl)
          .then((response) => {
            commit('set_see_all_entity_result', response.data.result);
            commit(
              'set_see_all_entity_result_list',
              response.data.result.results,
            );
            resolve(response);
          })
          .catch((error) => {
            console.error(
              'Error hitting see_all_entity_show_result_api API:',
              error,
            );
            reject(error);
          });
      });
    },
    group_result_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${APIs.group_api.replace('{id}', payload.id)}?entity_type=${
              payload.entity_type
            }`,
          )
          .then((response) => {
            commit('set_group_result', response.data);
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting feature_media_api API:', error);
            reject(error);
          });
      });
    },
  },
};

export default see_all_entity_store;
