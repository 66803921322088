import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const showoff_initial_result_store = {
  namespaced: true,
  state: {
    find_media_result: null,
    find_media_result_list: [],
  },

  mutations: {
    set_find_media_result(state, data) {
      state.find_media_result = data;
    },
    set_find_media_result_list(state, data) {
      state.find_media_result_list = state.find_media_result_list.concat(data);
    },
    reset_find_media_result_list(state) {
      state.find_media_result = null;
      state.find_media_result_list = [];
    },
  },

  actions: {
    get_find_media_result({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${APIs.find_media_api}?find_by=${payload.find_by}&q=${payload.query}&media_type=${payload.media_type}&page=${payload.page}`,
          )
          .then((response) => {
            commit('set_find_media_result', response.data);
            commit('set_find_media_result_list', response.data.result.results);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },
  },
};

export default showoff_initial_result_store;
