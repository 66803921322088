<template>
  <section class="border-1 border-borderColor rounded-3xl w-full p-5 mt-5">
    <div
      v-if="identify_media_data"
      class="flex sm:max-lg:flex-col max-[640px]:flex-col"
    >
      <div class="lg:w-3/4 max-[640px]:w-full mr-12 mt-16">
        <!-- Leftside -->
        <div
          class="shadow-[rgba(0,0,5,0.1)_0px_1px_5px_2px] py-10 px-5 rounded-3xl"
        >
          <div class="flex sm:max-lg:flex-col max-[640px]:flex-col">
            <div
              class="flex flex-col lg:w-[25%] max-[640px]:w-full sm:max-lg:items-center max-[640px]:items-center"
            >
              <img
                :src="identify_media_data.result.cover_image"
                alt=""
                class="max-w-[300px]"
              />
              <div class="flex justify-between my-3 gap-2">
                <div>
                  <h4 class="text-base font-bold">FORMAT</h4>
                  <p class="text-sm font-medium text-[#404558] opacity-40">
                    {{ identify_media_data.result.format }}
                  </p>
                </div>
                <div>
                  <h4
                    v-if="identify_media_data.result.media_type != null"
                    class="text-base font-bold"
                  >
                    <div>
                      {{
                        identifer_labels[
                          identify_media_data.result.media_type.toLowerCase()
                        ] || 'IDENTIFER'
                      }}
                    </div>
                  </h4>
                  <h4 v-else class="text-base font-bold">IDENTIFER</h4>
                  <p
                    v-for="(item, index) in identify_media_data.result
                      .identifier"
                    :key="index"
                    class="text-sm font-medium text-[#404558] opacity-40"
                  >
                    {{ item.identifier }}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex flex-col lg:ml-5 lg:w-[75%] max-[640px]:w-full">
              <h2 class="text-[28px] font-bold mb-2 max-[640px]:text-[20px]">
                {{ identify_media_data.result.title }}
              </h2>
              <div
                class="flex lg:justify-between mb-3 sm:max-lg:flex-wrap sm:max-lg:gap-5 max-[640px]:flex-wrap max-[640px]:gap-5"
              >
                <div class="flex flex-col">
                  <h3
                    v-if="identify_media_data.result.media_type != null"
                    class="text-xl text-[#00AFE7] font-bold max-[640px]:text-[16px]"
                  >
                    <div>
                      {{
                        creator_labels[
                          identify_media_data.result.media_type.toLowerCase()
                        ] || 'CREATOR'
                      }}
                    </div>
                  </h3>
                  <h3
                    v-else
                    class="text-xl text-[#00AFE7] font-bold max-[640px]:text-[16px]"
                  >
                    CREATOR
                  </h3>
                  <p
                    v-if="identify_media_data.result.media_credit.length > 0"
                    class="text-sm text-[#404558] opacity-40"
                  >
                    {{
                      identify_media_data.result.media_credit[0].media_credit
                        .name
                    }}
                  </p>
                  <p v-else class="text-sm text-[#404558] opacity-40">-</p>
                </div>
                <div class="flex flex-col">
                  <h3
                    v-if="identify_media_data.result.media_type != null"
                    class="text-xl text-[#00AFE7] font-bold max-[640px]:text-[16px]"
                  >
                    <div>
                      {{
                        released_labels[
                          identify_media_data.result.media_type.toLowerCase()
                        ] || 'RELEASED'
                      }}
                    </div>
                  </h3>
                  <h3
                    v-else
                    class="text-xl text-[#00AFE7] font-bold max-[640px]:text-[16px]"
                  >
                    RELEASED
                  </h3>
                  <p class="text-sm text-[#404558] opacity-40">
                    {{ identify_media_data.result.origin }}
                  </p>
                </div>
                <div class="flex flex-col">
                  <h3
                    class="text-xl text-[#00AFE7] font-bold max-[640px]:text-[16px]"
                  >
                    TYPE
                  </h3>
                  <p class="text-sm text-[#404558] opacity-40">
                    {{ identify_media_data.result.o_type || '-' }}
                  </p>
                </div>
                <div class="flex flex-col">
                  <h3
                    class="text-xl text-[#00AFE7] font-bold max-[640px]:text-[16px]"
                  >
                    TARGET AUDIENCE
                  </h3>
                  <div class="flex mt-1">
                    <p
                      v-for="(item, index) in identify_media_data.result
                        .target_audience"
                      :key="index"
                      class="text-[10px] text-[#4C6EF5] bg-[#EDF2FF] px-3 mr-2 rounded-2xl text-center"
                    >
                      {{ item }}
                    </p>
                  </div>
                </div>
              </div>
              <p class="text-sm">
                {{ identify_media_data.result.summary }}
              </p>
              <div class="border-b-1 border-black mt-10 my-5 opacity-10"></div>
              <div class="flex justify-between max-[560px]:flex-col">
                <div class="flex items-center">
                  <MediaLikeComponent
                    :identify_media_data="identify_media_data.result"
                  />
                  <span class="text-xl mx-3">Like</span>
                  <span class="text-base text-black opacity-40"
                    >{{ identify_media_data.result.like_count }} others liked
                    this book</span
                  >
                </div>
                <template v-if="identify_media_data.result.link">
                  <a :href="identify_media_data.result.link" target="_blank">
                    <button
                      class="text-sm text-blue p-3 border-2 border-blue rounded-full hover:text-white hover:bg-blue duration-500 max-[560px]:my-5"
                    >
                      Find this Media
                    </button>
                  </a>
                </template>
              </div>
            </div>
          </div>
          <template
            v-if="identify_media_data.result.additional_resources.length > 0"
          >
            <button
              class="text-sm text-white bg-blue font-medium py-2 px-2 mt-3 text-center rounded-full border-1 hover:text-blue hover:bg-white duration-500 hover:border-blue hover:border-1"
              @click.stop="additionalSources($event, 'identify')"
            >
              {{ showAdditionalSources ? 'Hide' : 'View' }} Additional Sources
            </button>
            <ul
              class="flex flex-col mt-5 gap-5 sm:text-center max-[640px]:text-center hidden"
              id="additionalSources-identify"
            >
              <template
                v-for="(add_src, index) in identify_media_data.result
                  .additional_resources"
                :key="index"
              >
                <li>
                  <span class="text-blue underline">
                    <a
                      v-if="add_src.link"
                      :href="add_src.link"
                      target="_blank"
                      >{{ add_src.description }}</a
                    ></span
                  >
                </li>
              </template>
            </ul>
          </template>
        </div>
      </div>

      <template v-if="identify_media_data">
        <div class="lg:w-1/4 max-[640px]:w-full">
          <p class="text-xl font-bold text-center my-4">
            ABOUT THE
            {{
              creator_labels[
                identify_media_data.result.media_type.toLowerCase()
              ] || 'CREATOR'
            }}
          </p>
          <div
            class="shadow-[rgba(0,0,5,0.1)_0px_1px_5px_2px] py-10 px-5 rounded-3xl"
          >
            <div
              v-for="(mc, index) in identify_media_data.result.media_credit"
              class="flex flex-col items-center"
              :key="index"
            >
              <img
                :src="mc.media_credit.image"
                alt="Creators"
                class="rounded-full w-[120px]"
              />
              <h4
                @click="
                  detail_navigation(mc.media_credit.id, mc.media_credit.entity)
                "
                class="text-xl text-blue font-bold mt-4 cursor-pointer"
              >
                {{ mc.media_credit.name }}
              </h4>
              <p class="text-sm text-black opacity-40 text-center my-2">
                {{
                  creator_labels[
                    identify_media_data.result.media_type.toLowerCase()
                  ] || 'CREATOR'
                }}
              </p>
              <div class="flex items-center mb-5">
                <MediaLikeComponent :identify_media_data="mc.media_credit" />
                <span class="text-xl mx-3">Like</span>
              </div>
              <p class="text-sm mb-5 h-[200px] overflow-y-auto">
                {{ mc.media_credit.summary }}
              </p>
              <template v-if="mc.media_credit.additional_resources.length > 0">
                <button
                  class="text-sm text-white bg-blue font-medium py-2 px-2 mt-3 text-center rounded-full border-1 hover:text-blue hover:bg-white duration-500 hover:border-blue hover:border-1"
                  @click="additionalSources($event, 'media-credits')"
                >
                  {{ showMCAditionalSources ? 'Hide' : 'View' }} Additional
                  Sources
                </button>
                <ul
                  class="flex flex-col mt-5 gap-5 sm:text-center max-[640px]:text-center hidden"
                  id="additionalSources-media-credits"
                >
                  <template
                    v-for="(add_src, index) in mc.media_credit
                      .additional_resources"
                    :key="index"
                  >
                    <li>
                      <span class="text-black underline">
                        <a
                          v-if="add_src.link"
                          :href="add_src.link"
                          target="_blank"
                          >{{ add_src.description }}</a
                        ></span
                      >
                    </li>
                  </template>
                </ul>
              </template>
            </div>
            <!-- <div v-else class="flex flex-col items-center">-</div> -->
          </div>
        </div>
      </template>
    </div>
    <div v-else>
      <div class="loading-container">
        <div class="loader"></div>
      </div>
      <!-- <div class="parent-container">
              <div class="animated-background">
                <div class="background-masker btn-divide-left"></div>
              </div>
            </div> -->
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { logout_messages } from '@/common_variables/logout_messages';
import { specific_page_routing } from '@/common_components/common_routing/specific_data_routing';
import { all_media_type_values } from '@/common_variables/media_type';
export default {
  name: 'IdentifyComponent',
  components: {
    MediaLikeComponent,
  },
  computed: {
    ...mapState({
      identify_media_data: (state) => state.identify_store.identify_media_data,
    }),
  },
  data() {
    return {
      loading: false,
      initialized: false,
      isLogin: false,
      showAdditionalSources: false,
      showMCAditionalSources: false,
      creator_labels: Object.keys(all_media_type_values).reduce((acc, key) => {
        acc[key] = all_media_type_values[key].creator_label;
        return acc;
      }, {}),

      released_labels: Object.keys(all_media_type_values).reduce((acc, key) => {
        acc[key] = all_media_type_values[key].released_label;
        return acc;
      }, {}),

      identifer_labels: Object.keys(all_media_type_values).reduce(
        (acc, key) => {
          acc[key] = all_media_type_values[key].identifer_label;
          return acc;
        },
        {},
      ),

      selectedMedia: 'All Media',
      label_media: '',
    };
  },

  async created() {
    this.set_identify_data_null();
    await this.fetchDataFromApis(this.$route.params.id);
    this.initialized = true;
  },

  methods: {
    ...mapActions('identify_store', ['identify_media_data_api']),
    ...mapMutations('identify_store', ['set_identify_data_null']),
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),

    async fetchDataFromApis(media_id) {
      this.loading = true;
      try {
        let promises = [this.identify_media_data_api({ id: media_id })];
        await Promise.all(promises);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },
    detail_navigation(id, component) {
      if (
        localStorage.getItem('token') === undefined ||
        localStorage.getItem('token') === null ||
        localStorage.getItem('token') === ''
      ) {
        this.set_show_login_popup(true);
        this.set_msg(logout_messages.specific_data_message);
      } else {
        specific_page_routing(this.$router, id, component);
      }
    },
    additionalSources(e, id) {
      const div_id = 'additionalSources-' + id;
      if (id == 'identify') {
        this.showAdditionalSources = !this.showAdditionalSources;
      } else {
        this.showMCAditionalSources = !this.showMCAditionalSources;
      }
      document.querySelector('#' + div_id).classList.toggle('hidden');
    },
  },
};
</script>

<style scoped>
.animated-background {
  height: 400px;
  /* Set your desired height here */
  width: 130%;
  background-color: #f0f0f0;
  /* Example background color */
  position: relative;
  /* If you need to position the inner divs */
  /* Add any other styles you need */
}

.background-masker {
  position: absolute;
  /* To position it within the parent */
  /* Add any other styles you need */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  transform: translateY(-200px);
}
</style>
