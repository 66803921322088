Vue file and store file

<template>
  <div class="w-full px-10 max-[767px]:px-5 mx-auto">
    <!-- Header Start -->
    <HomeHeader />
    <!-- Header End -->
    <div v-if="loading_data" class="loading-indicator">
      <div class="loader"></div>
    </div>
    <div v-else class="mx-auto my-10 font-inter max-[767px]:px-5">
      <div class="flex justify-between">
        <button
          class="px-10 py-4 max-[414px]:px-4 max-[414px]:py-1 text-blue text-lg font-medium border-1 border-blue rounded-full hover:text-white hover:bg-blue duration-500"
          @click="goBack"
        >
          Back
        </button>
      </div>
      <h2 class="text-5xl max-[767px]:text-3xl font-bold text-center mb-10">
        My Reviews
      </h2>
      <h2
        class="text-4xl text-blue max-[767px]:text-3xl font-bold text-center mb-10"
      >
        {{ this.$route.params.media_name }}
      </h2>
      <div class="flex justify-between max-[1024px]:flex-col">
        <div class="w-1/2 max-[1024px]:w-full mr-5 max-[1024px]:mb-10">
          <h2 class="text-4xl font-bold text-center text-[#25262B] mb-5">
            Review
          </h2>
          <div class="border-1 border-borderColor rounded-3xl p-5">
            <template v-if="!loading">
              <textarea
                v-model="review_data.result.review"
                rows="9"
                cols="50"
                class="w-full h-[300px]"
                placeholder="Enter Your Review"
              ></textarea>
            </template>
            <template v-else>
              <div class="relative w-full">
                <textarea
                  v-model="review_data.result.review"
                  rows="9"
                  cols="50"
                  class="w-full h-[300px]"
                  placeholder="Enter Your Review"
                ></textarea>
                <div
                  v-if="loading"
                  class="absolute inset-0 flex items-center justify-center pointer-events-none"
                >
                  <div class="loader"></div>
                </div>
              </div>
            </template>

            <div class="flex justify-center mt-4">
              <button
                @click="submit_review()"
                class="text-3xl text-white text-center bg-blue py-3 px-10 rounded-full"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div class="w-1/2 max-[1024px]:w-full">
          <h2 class="text-4xl font-bold text-center text-[#25262B] mb-5">
            Quotes
          </h2>
          <div class="border-1 border-borderColor rounded-3xl p-5 testing123">
            <ul class="h-[300px] overflow-y-auto mb-5">
              <template v-if="quote_data.result.length > 0">
                <QuoteComponent
                  v-for="(quote, index) in quote_data.result"
                  :key="index"
                  :data="quote"
                />
              </template>
              <template v-else> No quotes are added </template>
              <!-- <QuoteComponent /> -->
            </ul>
            <div class="flex justify-center">
              <button
                @click="add_quote()"
                class="text-3xl text-white text-center bg-blue py-3 px-10 rounded-full"
              >
                Add Quote
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Start -->
    <HomeFooter />
    <!-- Footer End -->
  </div>
</template>

<script>
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import QuoteComponent from './components/quote_component.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ReviewQuote',
  components: {
    HomeHeader,
    HomeFooter,
    QuoteComponent,
  },
  async created() {
    await this.fetchDataFromApis(this.$route.params.id);
  },
  computed: {
    ...mapState({
      review_data: (state) => state.review_quote_store.review_data,
    }),
    ...mapState({
      quote_data: (state) => state.review_quote_store.quote_data,
    }),
  },
  data() {
    return {
      loading: true,
      loading_data: true,
    };
  },
  methods: {
    ...mapActions('review_quote_store', [
      'get_review_data_api',
      'get_quote_data_api',
      'post_review_data_api',
    ]),

    async submit_review() {
      if (this.review_data.result.review) {
        this.loading = true;
        await Promise.all([
          this.post_review_data_api({
            review: this.review_data.result.review,
            discovery_media: this.$route.params.id,
          })
            .then(() => {
              this.$toast.open({
                message: 'Successfully save the review!',
                type: 'success',
                duration: 1000 * 5,
                dismissible: true,
              });
            })
            .catch((error) => {
              console.log(error);
              this.$toast.open({
                message: error,
                type: 'error',
                duration: 1000 * 5,
                dismissible: true,
              });
            }),
        ]);

        this.loading = false;
      } else {
        this.$toast.open({
          message: 'Enter the review ',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      }
    },

    add_quote() {
      this.$router.push({
        name: 'add-quote',
        params: {
          id: this.$route.params.id,
          media_name: this.$route.params.media_name,
        },
      });
    },

    async fetchDataFromApis(id) {
      this.loading_data = true;
      this.loading = true;
      try {
        await Promise.all([
          this.get_review_data_api({ id: id }),
          this.get_quote_data_api({ id: id }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
        this.loading_data = false;
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
