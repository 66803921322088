<template>
  <LoginPopup v-if="show_login_popup" />
  <div class="w-full px-5 mx-auto">
    <!-- Header Start -->
    <HomeHeader />
    <!-- Header End -->
    <template v-if="loading">
      <div v-if="loading" class="loading-indicator">
        <div class="loader"></div>
      </div>
    </template>
    <template v-else>
      <div v-if="home_search_result?.result" class="mx-auto font-inter">
        <div class="flex flex-col justify-center items-center py-[100px] bg-black" :style="{
          backgroundImage: `url(${require('../../images/media-search-bg.jpg')})`,
          backgroundSize: 'cover',
        }">
          <h2 class="text-5xl text-white text-center font-bold pb-16">
            Explore the Collection
          </h2>
          <SearchComponent @search="handleSearch" />
          <div class="relative -bottom-20 left-0 text-white  w-full p-2 flex justify-end">
            <a class="flex items-center max-[991px]:mb-5 cursor-pointer" @click="navigation_curiosity()">
              <span class="underline mr-2">Satisfy My Curiosity</span>
              <span>
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.0037 3.93955L2.39712 12.5312L0.98291 11.1195L9.5895 2.52779H2.00373V0.53125H13.0037V11.5122H11.0037V3.93955Z"
                    fill="white" />
                </svg>
              </span>
            </a>
          </div>
        </div>

        <!-- Group Results Section -->
        <div class="pb-5">
          <div v-if="!group_loading && groupItems.length > 0" class="relative flex items-center px-10 max-[767px]:px-5">
            <template v-if="groupItems.length > 4">
              <button class="absolute z-20 top-[40%] left-0" @click="scrollLeft" aria-label="Scroll left">
                <span class="icon-slider-leftarrow text-2xl"></span>
              </button>
              <button class="absolute z-20 top-[40%] right-0" @click="scrollRight" aria-label="Scroll right">
                <span class="icon-slider-rightarrow text-2xl"></span>
              </button>
            </template>
            <div class="flex items-center w-full mt-5 filter-slider-container" ref="filters" role="group"
              aria-label="Filter options">
              <GroupComponent v-for="item in groupItems" :key="item.key" :label="item.name" :count="item.count" :item_key="item.key"
                :selected_category="selected_category" @select="select_category" />
            </div>
          </div>
        </div>
        <div class="p-5">
          <SelectedFilters :selectedFilterNames="selectedFilterNames" :selectedFilterIds="selectedFilterIds"
            @remove-filter="handleRemoveFilter" />
        </div>

        <section class="lg:flex  relative mb-10  max-[767px]:mt-10">
          <div class="flex justify-between sm:justify-end items-center">
            <div class="flex gap-3 sm:hidden font-semibold text-xl">
              <span
                :class="['p-3 rounded-full text-blue cursor-pointer', viewMode === 'flat' ? 'bg-gray-200' : 'hover:bg-gray-200']"
                @click="toggleView('flat')">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                  class="bi bi-grid-3x2-gap" viewBox="0 0 16 16">
                  <path
                    d="M4 4v2H2V4zm1 7V9a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m5 5V9a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1M9 4v2H7V4zm5 0h-2v2h2zM4 9v2H2V9zm5 0v2H7V9zm5 0v2h-2V9zm-3-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm1 4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z" />
                </svg>
              </span>
              <span
                :class="['p-3 rounded-full text-blue cursor-pointer', viewMode === 'grid' ? 'bg-gray-200' : 'hover:bg-gray-200']"
                @click="toggleView('grid')">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-grid"
                  viewBox="0 0 16 16">
                  <path
                    d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
                </svg>
              </span>
            </div>
            <button class="mb-5 lg:hidden text-2xl font-semibold filter-button" @click.stop="toggleFilter">
              Filter
            </button>
          </div>
          <!-- Leftside Menu -->
          <div :class="[
            'leftside sticky top-0 bg-[#F6F6F6] py-8 rounded-lg w-[320px] max-[767px]:mb-10',
            { 'show-filter': isFilterOpen }
          ]" @click.stop>
            <div class="px-5">
              <h2 class="text-[24px] text-[#5F6368] font-inter font-[600] mb-5">Filter Results</h2>
              <template v-if="groups_result && groups_result.result">
                <!-- Media Type Category -->
                <div class="category-container">
                  <CategoryItem 
                    :category="{
                      id: 'media_type',
                      name: 'Media Type',
                      isHeader: true,
                      children: this.mediaTypeChildren
                    }"
                    :selected-filters="selectedFilters"
                    :expanded-categories="expandedCategories"
                    :is-top-level="true"
                    @toggle="toggleCategory"
                    @filter-change="handleFilterChange"
                  />
                </div>
                <!-- Existing Categories -->
                <div v-for="category in groups_result.result" :key="category.id">
                  <CategoryItem 
                    :category="category" 
                    :selected-filters="selectedFilters"
                    :expanded-categories="expandedCategories" 
                    :is-top-level="true" 
                    @toggle="toggleCategory"
                    @filter-change="handleFilterChange" 
                  />
                </div>
              </template>
            </div>
          </div>
          <!-- Rightside  -->
          <div class="right  md:pl-6 py-6 lg:w-[80%] w-full max-[767px]:px-5">
            <div class="flex justify-between items-center flex-wrap max-[767px]:gap-y-5">
              <span class="text-2xl text-gray-600">
                {{ searchCountText }}
              </span>
              <h2 class="text-2xl text-gray-500 max-[640px]:text-2xl font-bold">
                {{ searchParamsText }}
              </h2>
              <div class="hidden gap-3 sm:flex font-semibold text-xl">
                <span
                  :class="['p-3 rounded-full text-blue cursor-pointer', viewMode === 'flat' ? 'bg-gray-200' : 'hover:bg-gray-200']"
                  @click="toggleView('flat')">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                    class="bi bi-grid-3x2-gap" viewBox="0 0 16 16">
                    <path
                      d="M4 4v2H2V4zm1 7V9a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m5 5V9a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1M9 4v2H7V4zm5 0h-2v2h2zM4 9v2H2V9zm5 0v2H7V9zm5 0v2h-2V9zm-3-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm1 4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z" />
                  </svg>
                </span>
                <span
                  :class="['p-3 rounded-full text-blue cursor-pointer', viewMode === 'grid' ? 'bg-gray-200' : 'hover:bg-gray-200']"
                  @click="toggleView('grid')">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-grid"
                    viewBox="0 0 16 16">
                    <path
                      d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
                  </svg>
                </span>
              </div>
            </div>
            <div v-if="home_search_result.result.has_results" class="">
              <template v-for="(value, key) in filteredResults" :key="key">
                <template v-if="!exclude_params.includes(key) && value.count != 0">
                  <SearchedResults :key_prop="key" :value_prop="value" :search_topic_display="search_topic_display" :filters="this.selectedFilterIds" :viewMode="viewMode"/>
                </template>
              </template>
            </div>
            <div v-else class="sm:text-4xl text-2xl font-semibold mb-5 text-blue my-24 text-center">
              No Results Found
            </div>
          </div>
        </section>
      </div>
      <div class="recently-bottom-border border-b-1 border-borderColor mt-10 mb-7"></div>
    </template>
    <!-- Footer Start -->
    <HomeFooter />
    <!-- Footer End -->
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ref, reactive } from 'vue';
import { logout_messages } from '@/common_variables/logout_messages';
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import SearchedResults from './components/searched_results_component.vue';
import LoginPopup from '@/common_components/login_popup/login_popup.vue';
import SearchComponent from '@/controller/home/components/search_component.vue';
import CategoryItem from './components/catagory_item_component.vue';
import GroupComponent from '@/controller/media_data/components/common_component/group_component.vue';
import SelectedFilters from './components/selected_filter_component.vue';
import { all_media_type_values } from '@/common_variables/media_type';

export default {
  name: 'HomeSearch',
  components: {
    HomeHeader,
    HomeFooter,
    SearchedResults,
    LoginPopup,
    SearchComponent,
    CategoryItem,
    GroupComponent,
    SelectedFilters,
  },

  async created() {
    this.reset_home_search_result_list();
    
    // First fetch category data to ensure we have all categories loaded
    await this.fetchCategoryData();
    
    // Check for URL filters before initialization
    const urlFilters = this.$route.query.filters;
    if (urlFilters) {
      const filterIds = urlFilters.split(',');
      this.updateSelectedFiltersFromUrl(filterIds);
    }
    
    await this.initializeSearch();
    
  },

  setup() {
    const viewMode = ref('flat');
    const isLogin = ref(false);
    const loading = ref(false);
    const selectedFilters = reactive({});
    const expandedCategories = reactive({});
    const isFilterOpen = ref(false);

    return {
      viewMode,
      isLogin,
      loading,
      selectedFilters,
      expandedCategories,
      isFilterOpen,
    };
  },

  data() {
    return {
      previousSearchTopic: null,
      debounceTimeout: null,
      pendingApiCall: null,
      show_more_result: false,
      page: 1,
      search_topic_display: '',
      previously_selected_topic: null,
      exclude_params: ['has_results', 'search_query', 'counts'],
      selected_category: 'all',
      selectedFilterIds: [],
      selectedFilterNames: [],
      // Track if we're currently processing a request
      isProcessing: false,
      EntityDisplay : {
        all : 'All',
        media: 'Media',
        media_credit: 'Creator',
        character: 'Character',
        place: 'Place',
        item: 'Item',
        time_period: 'Time Period',
        genre: 'Genre',
        topic: 'Topic',
        theme: 'Theme',
        activity: 'Activity',
        purchase_oppurtunity: 'Purchase Opportunity',
        career_interest: 'Career Interest',
      },
      mediaTypeChildren: Object.values(all_media_type_values).map(type => ({
        id: type.media_type_key,
        name: type.singular,
        count: 0,
        isHeader: false
      })),
    };
  },

  mounted() {
    const token = localStorage.getItem('token');
    if (token === undefined || token === null || token === '') {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  computed: {
    ...mapState({
      home_search_result: (state) => state.home_search_store.home_search_result,
      show_login_popup: (state) => state.home_logout_store.show_login_popup,
      groups_result: (state) => state.home_search_store.groups_result,
    }),

    selectedEntityCount() {
      if (!this.home_search_result?.result?.counts) return 0;

      if (this.selected_category === 'all') {
        return this.home_search_result.result.counts.all || 0;
      }

      const entityKey = this.selected_category
      return this.home_search_result.result.counts[entityKey] || 0;
    },
    searchCountText() {
      const count = this.selectedEntityCount.toLocaleString();

      if (this.$route.name === 'explore') {
        return `${count} Results`;
      }

      if (this.selected_category === 'all') {
        return `${count} Results`;
      }

      return `${count} ${this.EntityDisplay[this.selected_category]} Results`;
    },

    searchParamsText() {
      if (this.$route.name === 'explore') {
        return this.EntityDisplay[this.selected_category];
      }
      if (!this.search_topic_display) {
        return 'all';
      }
      return this.search_topic_display;
    },

    filteredResults() {
      if (!this.home_search_result?.result || !this.home_search_result.result.has_results) {
        return {};
      }

      if (this.selected_category === 'all') {
        return this.home_search_result.result;
      }

      const entityKey = this.selected_category;
      if (!entityKey || !this.home_search_result.result[entityKey]) {
        return {};
      }

      // Return only the selected entity's data
      const filtered = {
        has_results: this.home_search_result.result.has_results,
        search_query: this.home_search_result.result.search_query,
        counts: { ...this.home_search_result.result.counts }
      };
      filtered[entityKey] = this.home_search_result.result[entityKey];
      return filtered;
    },

    groupItems() {
      if (!this.home_search_result?.result?.counts) return [];

      const counts = this.home_search_result.result.counts;
      return [
        { name: 'All', count: counts.all || 0, key: 'all' },
        { name: 'Media', count: counts.media || 0, key: 'media' },
        { name: 'Creator', count: counts.media_credit || 0, key: 'media_credit'},
        { name: 'Character', count: counts.character || 0 , key: 'character'},
        { name: 'Place', count: counts.place || 0, key: 'place' },
        { name: 'Item', count: counts.item || 0, key: 'item' },
        { name: 'Time Period', count: counts.time_period || 0, key: 'time_period' },
        { name: 'Genre', count: counts.genre || 0, key: 'genre' },
        { name: 'Topic', count: counts.topic || 0, key: 'topic' },
        { name: 'Theme', count: counts.theme || 0, key: 'theme' },
        { name: 'Activity', count: counts.activity || 0, key: 'activity' },
        { name: 'Purchase Opportunity', count: counts.purchase_oppurtunity || 0, key: 'purchase_oppurtunity' },
        { name: 'Career Interest', count: counts.career_interest || 0, key: 'career_interest' }
      ];
    },

    totalSearchResults() {
      if (!this.home_search_result.result || !this.home_search_result.result.has_results) {
        return 0;
      }

      let total = 0;
      Object.entries(this.home_search_result.result).forEach(([key, value]) => {
        if (!this.exclude_params.includes(key) && value.count) {
          total += value.count;
        }
      });
      return total;
    },

    formattedTotalResults() {
      return this.totalSearchResults.toLocaleString();
    }
  },

  methods: {
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),
    ...mapActions('home_search_store', ['home_search_result_api', 'groups_result_api']),
    ...mapMutations('home_search_store', ['reset_home_search_result_list']),

    handleRemoveFilter({ id, name }) {
      // Update the selected filters
      this.handleFilterChange(id, name, false);
    },

    resetAllFilters() {
      // Reset selected filters
      Object.keys(this.selectedFilters).forEach(key => {
        this.selectedFilters[key] = false;
      });
      this.selectedFilterIds = [];
      this.selectedFilterNames = [];

      // Reset category selection
      this.selected_category = 'all';

      // Reset expanded categories
      Object.keys(this.expandedCategories).forEach(key => {
        this.expandedCategories[key] = false;
      });
    },

    async handleSearchParamsChange(newSearchTopic) {
      // Reset filters and selections
      this.resetAllFilters();

      // Update search topic
      this.search_topic_display = newSearchTopic !== 'null' ? newSearchTopic : '';

      // Clear query parameters
      await this.$router.replace({
        query: {}
      });

      // Fetch new results with reset filters
      await this.fetchDataFromApis(this.search_topic_display);
    },

    async initializeSearch() {
      let query = '';

      // Handle entity from URL
      const urlEntity = this.$route.query.entity;
      if (urlEntity) {
        this.selected_category = urlEntity;
      }

      // Handle filters from URL
      const urlFilters = this.$route.query.filters;
      if (urlFilters) {
        const filterIds = urlFilters.split(',');
        this.updateSelectedFiltersFromUrl(filterIds);
      }

      if (this.$route.name === 'explore') {
        await this.fetchDataFromApis('');
        return;
      }

      query = this.$route.query.q || this.$route.params.search_topic;
      if (query === 'null') {
        query = '';
      }

      this.search_topic_display = query;
      await this.fetchDataFromApis(query);
    },

    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen;
    },

    closeFilter() {
      this.isFilterOpen = false;
    },

    handleClickOutside() {
      if (this.isFilterOpen) {
        this.closeFilter();
      }
    },

    scrollLeft() {
      if (this.$refs.filters) {
        this.$refs.filters.scrollBy({ left: -200, behavior: "smooth" });
      }
    },

    scrollRight() {
      if (this.$refs.filters) {
        this.$refs.filters.scrollBy({ left: 200, behavior: "smooth" });
      }
    },

    select_category(category) {
      this.selected_category = category;
      const query = {
        ...this.$route.query,
        entity: category === 'all' ? undefined : category
      };

      // Clean up undefined values
      Object.keys(query).forEach(key =>
        query[key] === undefined && delete query[key]
      );

      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query
      });

      this.fetchFilteredResults(this.search_topic_display, category);
    },

    async fetchCategoryData() {
      try {
        await this.groups_result_api();
        if (this.groups_result && this.groups_result.result) {
          this.initializeFilters(this.groups_result.result);
          this.groups_result.result.forEach(category => {
            this.expandedCategories[category.id] = true;
          });
        }
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    },

    toggleCategory(categoryId) {
      this.expandedCategories[categoryId] = !this.expandedCategories[categoryId];
    },

    initializeFilters(categories) {
      categories.forEach(category => {
        this.selectedFilters[category.id] = false;
        this.expandedCategories[category.id] = false;
        if (category.children) {
          this.initializeFilters(category.children);
        }
      });
    },

    handleFilterChange(id, name, isSelected) {
      // If already processing, don't trigger another request
      if (this.isProcessing) return;

      // Simply update the selected filter state for the specific ID
      this.selectedFilters[id] = isSelected;

      if (isSelected) {
        // Add the selected filter if not already present
        if (!this.selectedFilterIds.includes(id)) {
          this.selectedFilterIds.push(id);
          this.selectedFilterNames.push(name);
        }
      } else {
        // Remove the filter
        this.selectedFilterIds = this.selectedFilterIds.filter(filterId => filterId !== id);
        this.selectedFilterNames = this.selectedFilterNames.filter(filterName => filterName !== name);
      }

      // Cancel any pending debounce
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      // Debounce the API call and route update
      this.debounceTimeout = setTimeout(async () => {
        if (!this.isProcessing) {
          const newQuery = {
            ...this.$route.query,
            filters: this.selectedFilterIds.length > 0 ? this.selectedFilterIds.join(',') : undefined
          };

          // Clean up undefined values
          Object.keys(newQuery).forEach(key =>
            newQuery[key] === undefined && delete newQuery[key]
          );

          // Update the route silently using replace instead of push
          await this.$router.replace({
            name: this.$route.name,
            params: this.$route.params,
            query: newQuery
          }).catch(() => { });

          // Make the API call to fetch filtered results
          await this.fetchFilteredResults(
            this.search_topic_display,
            this.selected_category
          );
        }
      }, 300);
    },

    async fetchFilteredResults(searchTopic, entity) {
      // If already processing a request, don't start another one
      if (this.isProcessing) return;

      this.isProcessing = true;
      this.loading = true;

      try {
        const params = {
          search_query: searchTopic,
          filters: this.selectedFilterIds,
          entity_type: entity !== 'all' ? entity : undefined
        };

        await this.home_search_result_api(params);

        if (entity !== 'all') {
          const entityKey = entity;
          const currentData = this.home_search_result?.result || {};

          if (entityKey && currentData[entityKey]) {
            const filteredResult = {
              result: {
                has_results: currentData.has_results,
                search_query: currentData.search_query,
                counts: { ...currentData.counts }
              }
            };
            filteredResult.result[entityKey] = currentData[entityKey];
            this.$store.commit('home_search_store/set_home_search_result', filteredResult);
          }
        }
      } catch (error) {
        console.error('Error filtering results:', error);
      } finally {
        this.loading = false;
        this.isProcessing = false;
      }
    },
    // Add this helper method to your component
    // getEntityKey(entity) {
    //   return this.entityTypeMap[entity]?.toLowerCase();
    // },

    async fetchDataFromApis(search_topic) {
      // If already processing a request, don't start another one
      if (this.isProcessing) return;

      this.isProcessing = true;
      this.loading = true;

      try {
        const urlFilterIds = this.$route.query.filters ? this.$route.query.filters.split(',') : [];
        const entity = this.$route.query.entity;

        await this.home_search_result_api({
          search_query: search_topic,
          filters: urlFilterIds,
          entity_type: entity
        });

        if (this.$route.name === 'home-search' && search_topic && search_topic !== 'null') {
          await this.$router.push({
            name: 'home-search',
            params: {
              search_topic: search_topic,
              search_interest: 'null'
            },
            query: this.$route.query
          });
        }
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
        this.isProcessing = false;
      }
    },

    handleSearch(searchQuery) {
      this.$router.push({
        name: 'home-search',
        params: {
          search_topic: searchQuery || 'null',
          search_interest: 'null'
        }
      });
      this.fetchDataFromApis(searchQuery);
    },

    updateRoute(newQuery, newFilters) {
      const query = {
        ...this.$route.query,
        q: newQuery
      };

      if (newFilters && newFilters.length > 0) {
        query.filters = newFilters.join(',');
      } else if (!newFilters) {
        query.filters = this.$route.query.filters;
      }

      this.$router.push({
        path: this.$route.path,
        query
      });
    },

    updateSelectedFiltersFromUrl(filterIds) {
      // Reset all filters
      Object.keys(this.selectedFilters).forEach(key => {
        this.selectedFilters[key] = false;
      });
      this.selectedFilterIds = [];
      this.selectedFilterNames = [];

      filterIds.forEach(filterId => {
        // First check media type filters
        const mediaTypeFilter = this.mediaTypeChildren.find(type => type.id === filterId);
        if (mediaTypeFilter) {
          this.selectedFilters[filterId] = true;
          this.selectedFilterIds.push(filterId);
          this.selectedFilterNames.push(mediaTypeFilter.name);
          return;
        }

        // Then check other category filters
        const findAndUpdateFilter = (categories) => {
          for (const category of categories) {
            // Convert both IDs to strings for comparison
            if (category.id.toString() === filterId.toString()) {
              this.selectedFilters[category.id] = true;
              this.selectedFilterIds.push(category.id);
              this.selectedFilterNames.push(category.name);
              return true;
            }
            if (category.children) {
              // Check children recursively
              const foundInChildren = category.children.some(child => {
                if (child.id.toString() === filterId.toString()) {
                  this.selectedFilters[child.id] = true;
                  this.selectedFilterIds.push(child.id);
                  this.selectedFilterNames.push(child.name);
                  return true;
                }
                return false;
              });
              if (foundInChildren) return true;
            }
          }
          return false;
        };

        if (this.groups_result?.result) {
          findAndUpdateFilter(this.groups_result.result);
        }
      });
    },

    toggleView(mode) {
      this.viewMode = mode;
    },

    navigation_curiosity() {
      if (this.isLogin) {
        if (this.search_topic_display && this.search_topic_display.trim() !== '') {
          this.$router.push({
            name: 'search-satisfy',
            params: {
              search_topic: this.search_topic_display,
              search_interest: 'null',
            },
          });
        } else {
          this.$router.push({ name: 'satisfy-curiosity' });
        }
      } else {
        this.set_show_login_popup(true);
        this.set_msg(logout_messages.satisfy_curiosity);
      }
    }
  },

  watch: {
    '$route': {
      immediate: true,
      async handler(newRoute, oldRoute) {
        if (this.isProcessing) return;

        if (this.debounceTimeout) {
          clearTimeout(this.debounceTimeout);
        }

        // Check if search parameters have changed
        const oldSearchTopic = oldRoute?.params?.search_topic;
        const newSearchTopic = newRoute.params.search_topic;

        if (oldSearchTopic !== newSearchTopic) {
          // If search topic changed, reset everything
          await this.handleSearchParamsChange(newSearchTopic);
          return;
        }

        // Original route handling for other cases
        if (newRoute.name === 'explore') {
          if (!this.search_topic_display) {
            this.search_topic_display = '';
            this.selected_category = 'all';
            await this.initializeSearch();
          }
        } else if (newRoute.params.search_topic) {
          const searchTopic = newRoute.params.search_topic;
          this.search_topic_display = searchTopic !== 'null' ? searchTopic : '';
          await this.fetchDataFromApis(this.search_topic_display);
        }
      }
    },

    // Update query watcher to not interfere with search param changes
    '$route.query': {
      deep: true,
      async handler(newQuery, oldQuery) {
        if (this.isProcessing) return;

        if (this.debounceTimeout) {
          clearTimeout(this.debounceTimeout);
        }

        this.debounceTimeout = setTimeout(async () => {
          // Only process query changes if search topic hasn't changed
          if (this.$route.params.search_topic === this.previousSearchTopic) {
            if (!oldQuery ||
              newQuery.q !== oldQuery.q ||
              newQuery.filters !== oldQuery.filters ||
              newQuery.entity !== oldQuery.entity) {

              const isExplore = this.$route.name === 'explore';
              this.search_topic_display = isExplore ? '' : (newQuery.q || this.$route.params.search_topic);

              if (newQuery.filters) {
                const filterIds = newQuery.filters.split(',');
                this.updateSelectedFiltersFromUrl(filterIds);
              }

              if (newQuery.entity) {
                this.selected_category = newQuery.entity;
              }

              if (!isExplore || newQuery.q !== oldQuery?.q || newQuery.entity !== oldQuery?.entity) {
                await this.fetchDataFromApis(this.search_topic_display);
              }
            }
          }
        }, 300);

        // Update previous search topic
        this.previousSearchTopic = this.$route.params.search_topic;
      },
      immediate: true
    },
  }
};
</script>

<style scoped>
.leftside {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
}

@media (max-width: 1023px) {
  .leftside {
    position: fixed;
    left: -400px;
    /* Changed from -320px to -400px as requested */
    height: 100%;
    overflow-y: auto;
    z-index: 50;
    /* Changed to be above overlay but below max z-index */
    top: 0;
    transition: 0.3s all ease;
  }

  .show-filter {
    left: 0;
  }
}
</style>