<template>
  <li
    class="flex max-[991px]:flex-col max-[991px]:gap-y-3 max-[991px]:items-center mb-5 cursor-pointer"
    @click="media_data_navigation(data.id, data.entity)"
  >
    <img
      :src="data.image ? data.image : data.cover_image"
      alt=""
      class="max-w-[72px]"
    />
    <div class="flex flex-col justify-center max-[991px]:items-center ml-5">
      <p class="text-xl font-medium">
        {{ data.name ? data.name : data.title }}
      </p>
      <p class="text-base text-[#999FAA]">
        {{
          data.entity_display ? data.entity_display : data.media_type_display
        }}
      </p>
    </div>
  </li>
</template>

<script>
import { mapMutations } from 'vuex';
import { logout_messages } from '@/common_variables/logout_messages';
import { media_page_navigation } from '@/common_components/common_routing/media_page_routing';
import { specific_page_routing } from '@/common_components/common_routing/specific_data_routing';
import { all_entity_type_values } from '@/common_variables/entity_type';
export default {
  name: 'SearchCardComponent',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token === undefined || token === null || token === '') {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }
  },

  data() {
    return {};
  },
  methods: {
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),
    ...mapMutations('home_store', ['set_show_search']),
    media_data_navigation(id, type) {
      if (Object.keys(all_entity_type_values).includes(type)) {
        if (this.isLogin) {
          specific_page_routing(this.$router, id, type);
        } else {
          this.set_show_login_popup(true);
          this.set_msg(logout_messages.specific_data_message);
        }
        specific_page_routing(this.$router, id, type);
      } else {
        media_page_navigation(
          this.$router,
          id,
          this.data.title,
          this.data.media_type,
        );
      }
      this.set_show_search(false);
    },
  },
};
</script>

<style></style>
